import { initSwipe } from "../utils/mobile-gestures.js";
$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};
$(".section__season").each(function () {
    $(this).data('pos', 0);
});

$(".section__season .arrow").on("click", function (e) {
    e.preventDefault();

    let $this = $(e.currentTarget);
    let $carousel = $this.closest(".section__season");
    let pos = $carousel.data('pos');
    
    const $seasonInner = $carousel.find(".section__season-inner");
    let movieWidth = getComputedStyle($seasonInner[0]).getPropertyValue('--movie-width');
    let translateIteration = parseFloat(movieWidth);
    let maxPos = 1;
    const minPos = maxPos - $seasonInner.children().length + 2;

    if ($this.hasClass("next") && pos > minPos) {
        pos--;
    } else if ($this.hasClass("prev") && pos < maxPos) {
        pos++;
    }

    $carousel.data('pos', pos);
    $seasonInner.css('transform', 'translateX(' + (pos * translateIteration) + 'rem)');
});

$(".learn__more a").on("click", function (e) {
    $(".slide:last-child")[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
})


$(".section__questions .question").on("click", function (e) {
    $(e.target).find('.answer').toggleClass('show')
    $(e.target).find('.arrow').toggleClass('show')
})

let isScrolling = false;
let scrollTimeout;

$(window).on('load', function () {
    if ($('body').hasClass("page-seasons")) {
        if (window.innerWidth > 767) {
            document.addEventListener('wheel', function (event) {
                event.preventDefault();

                if (isScrolling) return;
                isScrolling = true;

                let currentSlide = $(event.target).closest('.slide')[0];
                let nextSlide;

                const delta = Math.sign(event.deltaY);
                if (delta > 0) {
                    nextSlide = $(currentSlide).next('.slide')[0];
                } else {
                    nextSlide = $(currentSlide).prev('.slide')[0];
                }

                if (nextSlide) {
                    nextSlide.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }

                scrollTimeout = setTimeout(() => {
                    isScrolling = false;
                }, 2000);
            }, { passive: false });
        } else {
            initSwipe(document)
            $('.slide:first-child').addClass('is-active')
            document.addEventListener('swipeDown', function (event) {
                event.preventDefault();
                let currentSlide = $('.slide.is-active');
                let nextSlide = $(currentSlide).next('.slide')[0];
                if (nextSlide) {
                    nextSlide.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    $(currentSlide).removeClass('is-active')
                    $(nextSlide).addClass('is-active')
                }
            })
            document.addEventListener('swipeUp', function (event) {
                event.preventDefault();
                let currentSlide = $('.slide.is-active');
                let nextSlide = $(currentSlide).prev('.slide')[0];
                if (nextSlide) {
                    nextSlide.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    $(currentSlide).removeClass('is-active')
                    $(nextSlide).addClass('is-active')
                }
            })
        }
    }
})

